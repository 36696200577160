import React from "react";
import "../Style/Service.css";
import gestion from "../Image/gestion.png";
import integration from "../Image/integration.jpg";
import animation from "../Image/animation.png";
import admin from "../Image/admin.png";
import responsive from "../Image/responsive.png";
import montage from "../Image/montage.png";

function Service() {
  return (
    <div className="service" id="service">
      <div className="titleService">
        <h1>Mes services</h1>
        <h2>Des prestations adaptées à vos besoins</h2>
      </div>
      <div className="containerCard">
        <div className="card">
          <img src={gestion} alt="groupe de personnes gestion de projet" />
          <div className="cardBody">
            <h3>Gestion de projet web</h3>
            <p>
              Site vitrine, corporate, évènementiel, e-commerce, intranet,
              application mobile.
            </p>
          </div>
        </div>
        <div className="card">
          <img src={integration} alt="personne programent en HTML et CSS" />
          <div className="cardBody">
            <h3>Intégration Web</h3>
            <p>
              Des intégrations (X)HTML / CSS respectueuses des standards du Web.
            </p>
          </div>
        </div>
        <div className="card">
          <img src={animation} alt="animation web" />
          <div className="cardBody">
            <h3>Dynamisme des pages</h3>
            <p>
              Des animations de contenu non intrusives pour embellir votre
              projet.
            </p>
          </div>
        </div>
        <div className="card">
          <img src={admin} alt="page administrateur" />
          <div className="cardBody">
            <h3>Interface admin</h3>
            <p>Outils spécifiques au bon fonctionnement de votre entreprise.</p>
          </div>
        </div>
        <div className="card">
          <img src={responsive} alt="site responsive" />
          <div className="cardBody">
            <h3>Responsive design</h3>
            <p>
              Interface compatible tous supports, tablette et application
              mobile.
            </p>
          </div>
        </div>
        <div className="card">
          <img src={montage} alt="motage d'un pc" />
          <div className="cardBody">
            <h3>Montage de PC</h3>
            <p>
              Assemblage de composants, installation système d'exploitation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
