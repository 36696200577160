import React, { Fragment, Component } from "react";
import Reaptcha from "reaptcha";

class ReCaptcha extends Component {
  constructor(props) {
    super(props);
    this.captcha = null;
  }

  onVerify = (recaptchaResponse) => {
    console.log(recaptchaResponse);
  };

  render() {
    return (
      <Fragment>
        <Reaptcha
          ref={(e) => (this.captcha = e)}
          sitekey="6Lfe5lIoAAAAAAsef4VPQa0Dpy9UtDKOLqasxD-9"
          onVerify={this.onVerify}
          size="invisible"
        />
      </Fragment>
    );
  }
}
export default ReCaptcha;
