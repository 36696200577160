import React from "react";
import "../Style/Home.css";
import background from "../Image/bg_01.jpg";

function Home() {
  return (
    <div
      className="container_home"
      id="home"
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="home">
        <div className="about">
          <h1>Alexis WALLEZ</h1>
          <h2>Développeur Informatique Indépendant</h2>
          {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
          <p>// Développeur web fullstack</p>
          {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
          <p>// Développeur d'application</p>
          {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
          <p>// Technicien informatique</p>
        </div>
        <div className="cardMe">
          <h2>À Propos</h2>
          <div className="text">
            <p>
              Bonjour et bienvenue sur mon portfolio. Je suis un développeur web
              et applicatif passionné par l’informatique depuis de nombreuses
              années. Au fil des ans, j’ai acquis une expérience professionnelle
              diversifiée dans le domaine de l’informatique, ce qui m’a permis
              de développer une expertise solide et une compréhension
              approfondie des technologies numériques.
            </p>
            <p>
              En plus de ma passion pour l’informatique, je suis également
              sapeur-pompier volontaire pendant mon temps libre. Cette
              expérience m’a appris l’importance du travail d’équipe, de la
              discipline et du dévouement au service des autres. Ces valeurs se
              reflètent dans mon travail en tant que développeur, où je
              m’efforce constamment de produire un travail de la plus haute
              qualité tout en respectant les délais.
            </p>
            <p>
              Je vous invite à explorer mon portfolio pour découvrir certains
              des projets sur lesquels j’ai travaillé et à me contacter si vous
              souhaitez discuter de collaborations potentielles. Merci de votre
              visite.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
