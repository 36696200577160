import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import {
  FaClock,
  FaEnvelope,
  FaIdCard,
  FaLocationDot,
  FaMessage,
  FaPenClip,
  FaPhone,
  FaUserLarge,
  FaCheck,
} from "react-icons/fa6";
import "../Style/Contact.css";

export const Contact = () => {
  const [hover, setHover] = useState(false);

  const Hover = () => {
    setHover(true);
  };
  const NoHover = () => {
    setHover(false);
  };

  const [coche, setCoche] = useState(false);

  const toggleCheckbox = () => {
    setCoche(!coche);
  };

  const [lastname, setLastame] = useState("");
  const [firstname, setFirstname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [object, setObject] = useState("");
  const [message, setMessage] = useState("");

  const [messageSend, setMessageSend] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setMessageSend(true);
    setLastame("");
    setFirstname("");
    setPhone("");
    setEmail("");
    setObject("");
    setMessage("");
    emailjs
      .sendForm(
        // "YOUR_SERVICE_ID"
        process.env.REACT_APP_SERVICE,
        //  "YOUR_TEMPLATE_ID"
        process.env.REACT_APP_TEMPLATE,
        form.current,
        // "YOUR_PUBLIC_KEY"
        process.env.REACT_APP_PUBLIC
      )
      .then(() => {
        setMessageError(false);
      })
      .catch(() => {
        setMessageSend(false);
        setMessageError(true);
      });
  };

  return (
    <div className="containerContact" id="contact">
      <div className="titleContact">
        <h1>Contact</h1>
        <h2>Une Idée ? Un Projet ? N'hésitez pas à me contacter !</h2>
      </div>
      <div className="contact">
        <div className="formMaps">
          <div className="containerForm">
            {messageSend ? (
              <div className="send">
                <FaCheck />
                <h2>E-mail envoyé avec succès !</h2>
                <p>Merci pour votre message !</p>
                <p>
                  Une réponse vous sera apportée dans les plus brefs délais.
                </p>
              </div>
            ) : (
              <div className="form">
                <form ref={form} onSubmit={sendEmail}>
                  {messageError && <p>Erreur réessayer ultérieurement.</p>}

                  <div className="input">
                    <div className="containerName">
                      <div className="name">
                        <div className="iconInput">
                          <FaUserLarge />
                        </div>
                        <h3>Nom</h3>
                        <input
                          placeholder="* Ex : Wallez"
                          id="lastname"
                          name="lastname"
                          type="text"
                          onChange={(e) => setLastame(e.target.value)}
                          value={lastname}
                          required
                        />
                      </div>
                      <div className="name">
                        <div className="iconInput">
                          <FaUserLarge />
                        </div>
                        <h3>Prénom</h3>
                        <input
                          placeholder="* Ex : Alexis"
                          id="firstname"
                          name="firstname"
                          type="text"
                          onChange={(e) => setFirstname(e.target.value)}
                          value={firstname}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="input">
                    <FaPhone />

                    <h3>Téléphone</h3>
                    <input
                      type="tel"
                      placeholder="Ex : 06 49 28 06 73"
                      id="phone"
                      name="phone"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                  </div>

                  <div className="input">
                    <FaEnvelope />

                    <h3>E-mail</h3>
                    <input
                      placeholder="* Ex : wallezalexis.pro@gmail.com"
                      id="email"
                      name="email"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                  </div>

                  <div className="input">
                    <FaPenClip />
                    <h3>Objet</h3>
                    <input
                      placeholder="* Ex : Demande d'un devis"
                      id="object"
                      name="object"
                      type="text"
                      onChange={(e) => setObject(e.target.value)}
                      value={object}
                      required
                    />
                  </div>

                  <div className="input">
                    <FaMessage />
                    <h3>Message</h3>
                    <textarea
                      placeholder="* Votre message..."
                      id="message"
                      name="message"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      required
                    />
                  </div>

                  <div className="containerCheckBox">
                    <input
                      className={`${hover ? "hover" : ""} checkbox`}
                      type="checkbox"
                      name="scales"
                      onClick={toggleCheckbox}
                      onChange={toggleCheckbox}
                      checked={coche}
                      required
                    />
                    <label
                      className="textBox"
                      onMouseEnter={Hover}
                      onMouseLeave={NoHover}
                      onClick={toggleCheckbox}
                    >
                      En soumettant ce formulaire, j'accepte que les
                      informations saisies soient exploitées dans le cadre de la
                      demande de contact et de la relation commerciale qui peut
                      en découler.
                    </label>
                  </div>

                  <button type="submit" className="button-80">
                    Envoyer
                  </button>
                </form>
              </div>
            )}
          </div>
          <div className="map-responsive">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2638.2535953568313!2d2.5583469764498377!3d48.604988871298914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e5e47a2dc66c4d%3A0x2da587b304a1d671!2s5%20All.%20des%20Aravis%2C%2077176%20Savigny-le-Temple!5e0!3m2!1sfr!2sfr!4v1695763067988!5m2!1sfr!2sfr"
              width="100%"
              height="100%"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              title="Responsive Google Maps"
            ></iframe>
          </div>
        </div>
        <div className="containerInfo">
          <div className="info">
            <FaLocationDot />
            <h3>Adresse</h3>
            <p>5 Allée des Aravis</p>
            <p>77176 Savigny-le-Temple</p>
          </div>

          {/* <div className="bar"></div> */}

          <div className="info">
            <FaPhone />
            <h3>Téléphone</h3>
            <a href="tel:+33649280673">(+33) 6 49 38 06 73</a>
          </div>

          <div className="info">
            <FaEnvelope />
            <h3>E-mail</h3>
            <a href="mailto:wallezalexis.pro@gmail.com">
              wallezalexis.pro@gmail.com
            </a>
          </div>

          <div className="info">
            <FaClock />
            <h3>Horaires</h3>
            <p>Lundi - Vendredi</p>
            <p>09H00 - 18H00</p>
          </div>

          <div className="info">
            <FaIdCard />
            <h3>SIREN</h3>
            <p>952 663 938</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
