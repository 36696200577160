import React, { useState } from "react";
import "../Style/Footer.css";
import {
  FaGithub,
  FaLinkedinIn,
  FaLink,
  FaEnvelope,
  FaLocationDot,
  FaPhone,
  FaXmark,
} from "react-icons/fa6";

function Footer() {
  const [mention, setMention] = useState(false);

  const Mention = () => {
    if (!mention) {
      setMention(true);
    } else {
      setMention(false);
    }
  };

  const MentionFasle = () => {
    setMention(false);
  };
  return (
    <div className="footer">
      <div className="topFooter">
        <a href="https://github.com/AlexWlz" target="_blank" rel="noreferrer">
          <FaGithub />
        </a>
        <a
          href="https://www.linkedin.com/in/alexis-wallez/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedinIn />
        </a>
      </div>

      <div className="bodyFooter">
        <div className="aboutFooter">
          <h2>Développeur Informatique Indépendant</h2>
          <p>
            Développeur Web front & back-end freelance, je suis à votre
            disposition pour répondre à tout type de projets de création de
            sites internet ou d'applications web.
          </p>
          <p>
            Passionné par les technologies liées au Web, je mets mes compétences
            au service de vos besoins dans divers domaines.
          </p>
        </div>

        <div className="aboutFooter">
          <h2>ALEXIS WALLEZ</h2>
          <p>
            <FaLocationDot /> 5, allée des Aravis 77176 Savigny-le-Temple
          </p>
          <p>
            <a href="mailto:wallezalexis.pro@gmail.com">
              <FaEnvelope /> wallezalexis.pro@gmail.com
            </a>
          </p>
          <p>
            <a href="tel:+33649280673">
              <FaPhone /> (+33) 6 49 28 06 73
            </a>
          </p>
          <a href="www.google.fr">
            <FaLink /> wallezalexis.com
          </a>
        </div>
      </div>

      <div className="bottomFooter">
        <div className="topBottomFooter">
          <h3 className="mention" onClick={Mention}>
            Mentions légales
          </h3>
          <h3>ALEXIS WALLEZ - COPYRIGHT © 2023</h3>
        </div>
        <p>
          Ce site est protégé par reCAPTCHA et la technologie Google{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Règles de confidentialité{" "}
          </a>
          et{" "}
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            Les conditions d'utilisation
          </a>{" "}
          s'appliquent.
        </p>
      </div>

      {mention && (
        <div className="mentionslegales">
          <div className="closeMention" onClick={MentionFasle}>
            <FaXmark />
          </div>
          <div className="legale legaletop">
            <h2>Mentions légales</h2>
            <h5>En vigueur depuis 01/09/2023</h5>
          </div>

          <div className="legale">
            <p>
              Conformément aux dispositions des Articles 6-III et 19 de la Loi
              n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
              numérique, dite L.C.E.N., il est porté à la connaissance des
              utilisateurs et visiteurs, ci-après l’"Utilisateur", du site
              https://www.wallezalexis.fr, ci-après le "Site", les présentes
              mentions légales.
            </p>
            <p>
              La connexion et la navigation sur le Site par l’Utilisateur
              implique acceptation intégrale et sans réserve des présentes
              mentions légales.
            </p>
            <p>
              Ces dernières sont accessibles sur le Site à la rubrique «
              Mentions légales ».
            </p>
          </div>

          <div className="legale">
            <h2>ARTICLE 1 - L'EDITEUR</h2>
            <p>
              L’édition et la direction de la publication du Site est assurée
              par WALLEZ Alexis, domiciliée 5 Allée des Aravis, 77176
              Savigny-le-Temple, France, dont le numéro de téléphone est +33 6
              49 28 06 73, et l'adresse électronique wallezalexis.pro@gmail.com.
            </p>
            <p>Ci-après l'"Editeur".</p>
          </div>

          <div className="legale">
            <h2>ARTICLE 2 - L'HEBERGEUR</h2>
            <p>
              L'hébergeur du Site est la société HOSTINGER, dont le siège social
              est situé au Kaunas, Lituanie Hostinger, UAB Jonavos g. 60C,
              44192, à contacter depuis https://www.hostinger.fr/contact
            </p>
          </div>
          <div className="legale">
            <h2>ARTICLE 3 - ACCES AU SITE</h2>
            <p>
              Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de
              force majeure, interruption programmée ou non et pouvant découlant
              d’une nécessité de maintenance.
            </p>
            <p>
              En cas de modification, interruption ou suspension du Site,
              l'Editeur ne saurait être tenu responsable.
            </p>
          </div>
          <div className="legale">
            <h2>ARTICLE 4 - COLLECTE DES DONNEES</h2>
            <p>
              Le Site assure à l'Utilisateur une collecte et un traitement
              d'informations personnelles dans le respect de la vie privée
              conformément à la loi n°78-17 du 6 janvier 1978 relative à
              l'informatique, aux fichiers et aux libertés.
            </p>
            <p>
              En vertu de la loi Informatique et Libertés, en date du 6 janvier
              1978, l'Utilisateur dispose d'un droit d'accès, de rectification,
              de suppression et d'opposition de ses données personnelles.
              L'Utilisateur exerce ce droit via un formulaire de contact.
            </p>
          </div>
          <div className="legale">
            <p>
              Toute utilisation, reproduction, diffusion, commercialisation,
              modification de toute ou partie du Site, sans autorisation de
              l’Editeur est prohibée et pourra entraînée des actions et
              poursuites judiciaires telles que notamment prévues par le Code de
              la propriété intellectuelle et le Code civil.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
