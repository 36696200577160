import React from "react";
import "../Style/Skill.css";
import background from "../Image/vue-dessus-classeur-anneaux.jpg";
import html from "../Image/html-5.png";
import css from "../Image/css-3.png";
import sass from "../Image/sass.png";
import php from "../Image/php.png";
import js from "../Image/js.png";
import ts from "../Image/ts.png";
import react from "../Image/react.png";
import nodejs from "../Image/nodejs.png";
import nosql from "../Image/nosql.png";
import mysql from "../Image/mysql.png";
import mongodb from "../Image/mongodb.png";
import postresql from "../Image/postgresql.png";
import angular from "../Image/angular.png"
import vuejs from "../Image/vuejs.png"
import python from "../Image/python.png"
import nextjs from "../Image/nextjs.png"

function Skill() {
  return (
    <div
      className="containerSkill"
      id="skill"
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="skillTop">
        <h1>Compétences</h1>
      </div>
      <div className="skill">
        <div className="cardSkill">
          <div className="titleSkill">
            <h2>Front-End</h2>
          </div>
          <div className="bodySkill">
            <img src={html} alt="logo html" />
            <img src={css} alt="logo css" />
            <img src={sass} alt="logo sass" />
            <img src={react} alt="logo react" />
            <img src={angular} alt="logo react" />
            <img src={vuejs} alt="logo react" />
            <img src={js} alt="logo javascript" className="js" />
            <img src={ts} alt="logo typescript" />
          </div>
        </div>

        <div className="cardSkill">
          <div className="titleSkill">
            <h2>Back-End</h2>
          </div>
          <div className="bodySkill little">
            <img src={php} alt="logo php" />
            <img src={python} alt="logo php" />
            <img src={nodejs} alt="logo nodejs" />
            <img src={nextjs} alt="logo nodejs" />
          </div>
        </div>

        <div className="cardSkill">
          <div className="titleSkill">
            <h2>Base de données</h2>
          </div>
          <div className="bodySkill little">
            <img src={nosql} alt="logo nosql" />
            <img src={mysql} alt="logo mysql" />
            <img src={mongodb} alt="logo mongodb" />
            <img src={postresql} alt="logo postresql" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skill;
