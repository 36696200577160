import "../Style/App.css";
import React, { useState } from "react";
import { CgMenuGridO, CgCloseR } from "react-icons/cg";
import { IoHomeSharp } from "react-icons/io5";
import Home from "./Home";
import Service from "./Service";
import Skill from "./Skill";
// import Project from "./Project";
import Contact from "./Contact";
import Footer from "./Footer";
import ReCaptcha from "./ReCaptcha";

function App() {
  const [burger, setBurger] = useState(false);

  const Burger = () => {
    if (!burger) {
      setBurger(true);
    } else {
      setBurger(false);
    }
  };

  const BurgerFalse = () => {
    setBurger(false);
  };

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    const scrollThreshold = 25;

    if (scrollTop >= scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <div className={`App ${burger ? "boxShadow" : ""}`} onScroll={handleScroll}>
      {burger && <div className="display"></div>}
      <div className={`navbar ${scrolled ? "navbarWhite" : ""}`}>
        <div className="container_icons">
          <a onClick={BurgerFalse} href="#home" className={`icon homeIcon`}>
            <IoHomeSharp />
          </a>
        </div>
        <div className="container_burger container_icons">
          <button
            onClick={Burger}
            className={`icon ${burger ? "burger active" : "burger"}`}
          >
            {burger ? <CgCloseR /> : <CgMenuGridO />}
          </button>
        </div>

        <div className="link">
          <a href="#service" className={``}>
            Services
          </a>
          <a href="#skill" className={``}>
            Compétences
          </a>
          {/* <a href="#project" className={``}>
            Projets
          </a> */}
          <a href="#contact" className={``}>
            Contact
          </a>
        </div>
        {burger && (
          <div className="menu">
            <a onClick={BurgerFalse} href="#service" className={``}>
              Services
            </a>
            <a onClick={BurgerFalse} href="#skill" className={``}>
              Compétences
            </a>
            {/* <a onClick={BurgerFalse} href="#project" className={``}>
              Projets
            </a> */}
            <a onClick={BurgerFalse} href="#contact" className={``}>
              Contact
            </a>
          </div>
        )}
      </div>

      <div className="body">
        <ReCaptcha />
        <Home />
        <Service />
        <Skill />
        {/* <Project /> */}
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
